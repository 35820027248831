import React from "react"

import Logo from "../../assets/images/Logo.svg"
import "./AuthenticateHeader.scss"

const AuthenticateHeader = () => {
  return (
    <div className="authenticate-header">
      <img src={Logo} className="authenticate-header__logo" />

      <p className="authenticate-header__text">Self service laundromat</p>
    </div>
  )
}

export default AuthenticateHeader
