import React, { useContext, useEffect, useState } from "react"

import { navigate } from "gatsby"

import { AuthenticationContext } from "../../context/"
import Link from "../../utils/link"
import Divider from "../UI/Divider"
import GoogleButton from "../UI/GoogleButton"

import "./LoginComponent.scss"

const LoginComponent = () => {
  const {
    logIn,
    getLocation,
    isLoadingRequest,
    setIsLoadingRequest,
  } = useContext(AuthenticationContext)

  useEffect(() => {
    const localUser = localStorage.getItem("user")
    if (getLocation() === null) {
      navigate("/")
    } else if (localUser) {
      navigate("/home")
    }
  }, [])

  const initialValue = {
    email: "",
    password: "",
  }

  const [value, setValues] = useState(initialValue)

  const handleInputChange = (e) => {
    const { name, value } = e.target

    setIsLoadingRequest(false)

    setValues((prev) => ({
      ...prev,
      [name]: name === "email" ? value.toLowerCase() : value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const config = { email: value.email, password: value.password }
    logIn(config)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault()
    }
  }

  return (
    <div className="login-component">
      <div>
        <p className="login-component__title">Login to your Account</p>

        <GoogleButton text="Login with Google" />

        <div className="login-component__divider">
          <Divider text="Or"></Divider>
        </div>

        <form className="login-component__form" onSubmit={handleSubmit}>
          <div className="login-component__form__input">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={value.email}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              required
              placeholder="Please Enter"
              pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
              title="Please include a domain name in the email"
            />
          </div>
          <div className="login-component__form__input">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              onChange={handleInputChange}
              required
              placeholder="Please Enter"
            />
          </div>
          <button
            type="submit"
            disabled={isLoadingRequest}
            className="login-component__form__submit"
          >
            Login
          </button>

          <Link
            to="/forgot-password"
            className="login-component__form__forgot-password"
          >
            Forgot your password?
          </Link>
        </form>
      </div>
      <div className="login-component__mob">
        <Divider></Divider>
      </div>
      <div>
        <div className="login-component__bottom">
          <p className="login-component__bottom__heading">
            New to Washroom? <br />
            Register an account today.
          </p>

          <p className="login-component__description">
            We’re committed to bringing a fresh take on an industry that’s
            largely wash rinse and repeat. Follow our simple sign-up process to
            get the full Washroom experience, including access to our new
            hassle-free locker service.
          </p>

          <Link to="/register" className="login-component__bottom__register">
            Register
          </Link>

          <div className="login-component__email-link">
            <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginComponent
