import React from "react"

import "./AuthenticateFooter.scss"

const AuthenticateFooter = () => {
  return (
    <div className="authenticate-footer">
      <div className="authenticate-footer__container">
        <p className="authenticate-footer__text">
          256 Arden Street <br />
          Coogee NSW 2034
        </p>
        <p className="authenticate-footer__text">
          65A Macpherson Street <br />
          Waverley NSW 2024
        </p>
        <p className="authenticate-footer__text">
          R1107/488 Pacific Hwy <br />
          St Leonards sq.
        </p>
        <p className="authenticate-footer__text">
          110 Avoca Street <br />
          Randwick NSW 2031
        </p>
      </div>
      <div className="authenticate-footer__email-link">
        <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
      </div>
    </div>
  )
}

export default AuthenticateFooter
