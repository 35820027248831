import React from "react"

import AuthenticateFooter from "../components/LoginRegister/AuthenticateFooter"
import AuthenticateHeader from "../components/LoginRegister/AuthenticateHeader"
import LoginComponent from "../components/LoginRegister/LoginComponent"

import "../components/LoginRegister/Authenticate.scss"

const login = () => {
  return (
    <div className="authenticate">
      <AuthenticateHeader />
      <div className="authenticate__main">
        <div className="authenticate__main__container">
          <LoginComponent />
        </div>
      </div>
      <AuthenticateFooter />
    </div>
  )
}

export default login
